<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light"
  (window:scroll)="windowScroll()" id="navbar">
  <div class="container">
    <!-- LOGO -->
    <a class="logo text-uppercase" href="/">
      <img src="assets/images/logo-light.png" class="logo-light" alt="" height="30">
      <img src="assets/images/logo-dark.png" alt="" class="logo-dark" height="30" />
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
        <!--<li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
          <a [ngxScrollTo]="'#home'" href="javascript: void(0);" class="nav-link">Inicio</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'services'}">
          <a [ngxScrollTo]="'#services'" href="javascript: void(0);" class="nav-link">Características</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
          <a [ngxScrollTo]="'#features'" href="javascript: void(0);" class="nav-link">Destacados</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'clients'}">
          <a [ngxScrollTo]="'#clients'" href="javascript: void(0);" class="nav-link">Testimonios</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'pricing'}">
          <a [ngxScrollTo]="'#pricing'" href="javascript: void(0);" class="nav-link">Plan de precios</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'contact'}">
          <a [ngxScrollTo]="'#contact'" href="javascript: void(0);" class="nav-link">Contacto</a>
        </li>-->
      </ul>
      <a class="btn btn-success btn-rounded navbar-btn" routerLink="/eventos">Zona personal</a>

    </div>
  </div>
</nav>
<!-- Navbar End -->
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">

  <!-- Hero section Start -->
  <ngb-carousel class="home-carousel" #carousel *ngIf="images" [animation]="true" [interval]="7000" [pauseOnHover]="false">
    <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img [src]="images[0]" alt="Random first slide">
        </div>
        <div class="bg-overlay"></div>
        <div class="carousel-caption">
          <p class="text-white-50">Sistema de gestión de reuniones y presentación de productos</p>
          <h1 class="hero-title text-white mb-4"><img src="assets/images/logo-light.png" class="logo-light" alt=""></h1>
        </div>
    </ng-template>
    <!--<ng-template ngbSlide>
        <div class="picsum-img-wrapper">
            <img [src]="images[1]" alt="Random second slide">
        </div>
        <div class="bg-overlay"></div>
        <div class="carousel-caption">
          <p class="font-16 text-uppercase text-white-50">Eventos con garantías</p>
          <h1 class="hero-title text-white mb-4">Organizar cualquier evento es posible con <span class="text-primary">ContrALL</span></h1>
          <p class="text-white-50">La solución a requerimientos sanitarios de aforo en eventos Post-COVID19</p>
        </div>
    </ng-template>
    <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
            <img [src]="images[2]" alt="Random third slide">
        </div>
        <div class="bg-overlay"></div>
        <div class="carousel-caption">
          <p class="font-16 text-uppercase text-white-50">Eficiencia y tranquilidad</p>
          <h1 class="hero-title text-white mb-4">Olvida el papel, la gestión de tu evento con <span class="text-primary">ContrALL</span></h1>
          <p class="text-white-50">Organiza conferencias, jornadas, exposiciones o degustaciones con éxito</p>
        </div>
    </ng-template>-->
  </ngb-carousel>
  <app-footer></app-footer>
</div>
