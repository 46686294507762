import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import  { WsSuccessFunction, WsErrorFunction, WebServiceUtils } from '../utils/web-service-utils';

import { environment } from '../../environments/environment';
import { SessionService } from './session.service';

import { EventoDto } from '../models/event-info';
import { EventoFirebaseDto } from '../models/event-firebase-info';

@Injectable({
  providedIn: 'root'
})
export class EventoService {

  constructor(private http: HttpClient,
    private sessionService: SessionService) { }

  /**
   * Listado de eventos disponibles en ContrALL
   * @param onSuccess respuesta OK (datos de eventos)
   * @param onError respuesta KO
   */
   public getEventosList(onSuccess:WsSuccessFunction<EventoDto[]>, onError: WsErrorFunction<boolean>): void {

    const opts = {
      headers: {
        'Authorization': 'Bearer ' + this.sessionService.loadSessionInfo().accessToken
      }
    };

    this.http.get<EventoDto[]>(environment.apiEventosListUrl, opts).subscribe(
      resp => {
        onSuccess(resp);
      },
      (err: HttpErrorResponse) => {
        onError(null, err);
      }
    )
  }

  /**
   * Listado de eventos registrados para el usuario
   * @param onSuccess respuesta OK (datos de eventos)
   * @param onError respuesta KO
   */
   public getEventosUsuarioList(onSuccess:WsSuccessFunction<EventoFirebaseDto[]>, onError: WsErrorFunction<boolean>): void {

    const opts = {
      headers: {
        'Authorization': 'Bearer ' + this.sessionService.loadSessionInfo().accessToken
      }
    };

    this.http.get<EventoFirebaseDto[]>(environment.apiEventosUsuarioListUrl, opts).subscribe(
      resp => {
        onSuccess(resp);
      },
      (err: HttpErrorResponse) => {
        onError(null, err);
      }
    )
  }

  /**
   * Detalle de una evento
   * @param idEvento identificador del evento
   * @param onSuccess respuesta OK (datos del evento)
   * @param onError respuesta KO
   */
   public getEvento(idEvento: number, onSuccess:WsSuccessFunction<EventoDto>, onError: WsErrorFunction<boolean>): void {

    const opts = {
      headers: {
        'Authorization': 'Bearer ' + this.sessionService.loadSessionInfo().accessToken
      }
    };

    this.http.get<EventoDto>(environment.apiEventoUrl + idEvento, opts).subscribe(
      resp => {
        onSuccess(resp);
      },
      (err: HttpErrorResponse) => {
        onError(null, err);
      }
    )
  }


  /**
   * Nueva inscripción en el evento
   * @param idEvento identificador del evento
   * @param onSuccess respuesta OK (inscripción ok)
   * @param onError respuesta KO
   */
   public eventoRegistro(idEvento: number, onSuccess:WsSuccessFunction<boolean>, onError: WsErrorFunction<boolean>): void {
       
        const opts = {
           headers: {
               'Authorization': 'Bearer ' + this.sessionService.loadSessionInfo().accessToken
            }
        };

        this.http.post<boolean>(environment.apiEventoInscripcionUrl + idEvento, null, opts).subscribe(
            resp => {
                onSuccess(resp);
            },
            (err: HttpErrorResponse) => {
                onError(null, err);
        });
  }


  /**
   * Nueva inscripción en el evento
   * @param idEvento identificador del evento
   * @param onSuccess respuesta OK (inscripción ok)
   * @param onError respuesta KO
   */
  public eventoBaja(idEvento: number, onSuccess:WsSuccessFunction<boolean>, onError: WsErrorFunction<boolean>):void {
    const opts = {
      headers: {
        'Authorization': 'Bearer ' + this.sessionService.loadSessionInfo().accessToken
      }
    };
    
    this.http.post<boolean>(environment.apiEventoBajaUrl + idEvento, null, opts).subscribe(
      resp => {
        onSuccess(resp);
      },
      (err: HttpErrorResponse) => {
        onError(null, err);
      });
  }
}